<template>
  <div>
    <vs-row class="w-full">
    <vs-col class="w-full">
      <vs-row class="w-full">
        <vs-col vs-w="4">
          <vs-divider>Agendamento</vs-divider>
          <sig-switch
            v-model="model.notify_debit"
            :label="model.$fields.notify_debit.label"
            position="bottom"
            class="w-full"
          />
          <sig-switch
            v-model="model.block_debit"
            :label="model.$fields.block_debit.label"
            position="bottom"
            class="w-full"
          />
          <sig-switch
            v-model="model.double_class"
            :label="model.$fields.double_class.label"
            position="bottom"
            disabled="true"
            class="w-full"
          />
        </vs-col>
        <vs-col vs-w="4" class="pl-2">
          <vs-divider>Aluno faltou aula</vs-divider>
          <switch-automatic-transaction
            v-model="model.fine_practical_class"
            entity="PRACTICAL_CLASS"
            label="Lançar multa na aula prática"
            observation="Lançar multa automaticamente se o aluno faltar aula prática"
            position="bottom"
            class="w-full"
          />
          <switch-automatic-transaction
            v-model="model.fine_theoretical_class"
            entity="THEORETICAL_CLASS"
            label="Lançar multa na aula teórica"
            observation="Lançar multa automaticamente se o aluno faltar aula teórica"
            position="bottom"
            class="w-full"
          />
        </vs-col>
        <vs-col vs-w="4" class="pl-2">
          <vs-divider>-</vs-divider>
        </vs-col>
      </vs-row>
      <vs-row class="w-full">
        <vs-col class="flex flex-row-reverse">
          <vs-button color="primary" type="filled" @click="save"
            >Salvar</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-col>
  </vs-row>
  </div>
</template>

<script>
import SigInputEnum from '@/components/sig/SigInputEnum.vue'
import SigVselect from '@/components/sig/SigVselect.vue'
import SwitchAutomaticTransaction from '@/components/cashier/transaction/SwitchAutomaticTransaction.vue'
import model from '@/store/schedule/configSchedule/model'

export default {
  components: {
    SigVselect,
    SwitchAutomaticTransaction,
    SigInputEnum
  },
  data () {
    return {
      model
    }
  },
  computed: {
    single: {
      get () {
        return this.$store.state[this.model.$store.name].single
      },
      set (value) {
        this.$store.commit(this.model.$store.commit.single, value)
      }
    }
  },
  methods: {
    save () {
      this.$vs.loading()
      this.single = this.model
      this.$store
        .dispatch(this.model.$store.dispatch.saveSingle)
        .then(() => {
          this.mixinNotify('Operação executada com sucesso')
        })
        .catch(this.mixinCatch)
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    async fetch () {
      await this.$store.dispatch(this.model.$store.dispatch.single)
      this.updateModel()
    },
    updateModel () {
      this.model = this.single.$clone()
    }
  },
  mounted () {
    if (this.single && this.single.id) this.updateModel()
    else this.fetch()
  }
}
</script>
