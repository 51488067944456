var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vx-row" },
        [
          _c("select-service", {
            staticClass: "vx-col md:w-1/2",
            attrs: { "use-id": true, appendToBody: true },
            model: {
              value: _vm.service,
              callback: function ($$v) {
                _vm.service = $$v
              },
              expression: "service",
            },
          }),
          _c("select-account-plan", {
            staticClass: "vx-col md:w-1/2",
            attrs: { "use-id": true, appendToBody: true },
            model: {
              value: _vm.accountPlan,
              callback: function ($$v) {
                _vm.accountPlan = $$v
              },
              expression: "accountPlan",
            },
          }),
        ],
        1
      ),
      _c("select-type-payment-and-account", {
        attrs: { appendToBody: true },
        model: {
          value: _vm.typePaymentAndAccount,
          callback: function ($$v) {
            _vm.typePaymentAndAccount = $$v
          },
          expression: "typePaymentAndAccount",
        },
      }),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex flex-row-reverse" },
        [_c("vs-button", { on: { click: _vm.save } }, [_vm._v("Salvar")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }