var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      attrs: { active: _vm.localActive, title: _vm.label },
      on: {
        "update:active": function ($event) {
          _vm.localActive = $event
        },
      },
    },
    [
      _c("automatic-transaction", {
        on: {
          saved: function ($event) {
            _vm.localActive = false
          },
        },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }