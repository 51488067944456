var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-row",
        { staticClass: "w-full" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c(
                "vs-row",
                { staticClass: "w-full" },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "4" } },
                    [
                      _c("vs-divider", [_vm._v("Agendamento")]),
                      _c("sig-switch", {
                        staticClass: "w-full",
                        attrs: {
                          label: _vm.model.$fields.notify_debit.label,
                          position: "bottom",
                        },
                        model: {
                          value: _vm.model.notify_debit,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "notify_debit", $$v)
                          },
                          expression: "model.notify_debit",
                        },
                      }),
                      _c("sig-switch", {
                        staticClass: "w-full",
                        attrs: {
                          label: _vm.model.$fields.block_debit.label,
                          position: "bottom",
                        },
                        model: {
                          value: _vm.model.block_debit,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "block_debit", $$v)
                          },
                          expression: "model.block_debit",
                        },
                      }),
                      _c("sig-switch", {
                        staticClass: "w-full",
                        attrs: {
                          label: _vm.model.$fields.double_class.label,
                          position: "bottom",
                          disabled: "true",
                        },
                        model: {
                          value: _vm.model.double_class,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "double_class", $$v)
                          },
                          expression: "model.double_class",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { staticClass: "pl-2", attrs: { "vs-w": "4" } },
                    [
                      _c("vs-divider", [_vm._v("Aluno faltou aula")]),
                      _c("switch-automatic-transaction", {
                        staticClass: "w-full",
                        attrs: {
                          entity: "PRACTICAL_CLASS",
                          label: "Lançar multa na aula prática",
                          observation:
                            "Lançar multa automaticamente se o aluno faltar aula prática",
                          position: "bottom",
                        },
                        model: {
                          value: _vm.model.fine_practical_class,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "fine_practical_class", $$v)
                          },
                          expression: "model.fine_practical_class",
                        },
                      }),
                      _c("switch-automatic-transaction", {
                        staticClass: "w-full",
                        attrs: {
                          entity: "THEORETICAL_CLASS",
                          label: "Lançar multa na aula teórica",
                          observation:
                            "Lançar multa automaticamente se o aluno faltar aula teórica",
                          position: "bottom",
                        },
                        model: {
                          value: _vm.model.fine_theoretical_class,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "fine_theoretical_class", $$v)
                          },
                          expression: "model.fine_theoretical_class",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { staticClass: "pl-2", attrs: { "vs-w": "4" } },
                    [_c("vs-divider", [_vm._v("-")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-row",
                { staticClass: "w-full" },
                [
                  _c(
                    "vs-col",
                    { staticClass: "flex flex-row-reverse" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { color: "primary", type: "filled" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Salvar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }