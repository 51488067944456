<template>
  <vs-popup :active.sync="localActive" :title="label">
    <automatic-transaction v-model="localValue" @saved="localActive = false" />
  </vs-popup>
</template>

<script>
import AutomaticTransaction from './AutomaticTransaction.vue'
export default {
  components: {
    AutomaticTransaction
  },
  props: {
    active: {},
    label: {},
    value: {}
  },
  computed: {
    localActive: {
      get () {
        return this.active
      },
      set (value) {
        this.$emit('update:active', value)
      }
    },
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style>
</style>
