<template>
  <div>
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Geral" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <schedule-config />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ScheduleConfig from '@/components/schedule/ScheduleConfig'

export default {
  components: {
    ScheduleConfig
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>

<style lang="scss">
#financiel-register-tabs {
  .vs-tabs--content {
    padding: 0 10px 23px !important;
  }
}
</style>
