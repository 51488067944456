var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("automatic-transaction-modal", {
        ref: "modal",
        attrs: {
          active: _vm.popupAutomaticTransaction,
          label: "Configurar transação automática",
        },
        on: {
          "update:active": [
            function ($event) {
              _vm.popupAutomaticTransaction = $event
            },
            _vm.disableFeeIfServiceNotSelected,
          ],
          saved: function ($event) {
            return _vm.$emit("saved")
          },
        },
        model: {
          value: _vm.automaticTransaction,
          callback: function ($$v) {
            _vm.automaticTransaction = $$v
          },
          expression: "automaticTransaction",
        },
      }),
      _c("sig-switch", {
        staticClass: "w-full",
        attrs: {
          label: _vm.label,
          position: _vm.position,
          observation: _vm.observation,
        },
        on: { click: _vm.enableFinePracticalClass },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }