<template>
  <div>
    <automatic-transaction-modal
      v-model="automaticTransaction"
      :active.sync="popupAutomaticTransaction"
      @update:active="disableFeeIfServiceNotSelected"
      @saved="$emit('saved')"
      ref="modal"
      label="Configurar transação automática"
    />
    <sig-switch
      v-model="localValue"
      :label="label"
      :position="position"
      :observation="observation"
      class="w-full"
      @click="enableFinePracticalClass"
    />
  </div>
</template>

<script>
import AutomaticTransactionModal from './AutomaticTransactionModal'
import axios from '@/axios'
export default {
  components: {
    AutomaticTransactionModal
  },
  props: {
    value: {},
    position: {},
    label: {},
    observation: {},
    entity: {}
  },
  data () {
    return {
      popupAutomaticTransaction: false,
      automaticTransaction: {
        entity: this.entity,
        type_payment_id: 10
      }
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    enableFinePracticalClass () {
      if (!this.value) this.popupAutomaticTransaction = true
      if (this.automaticTransaction.id === undefined) this.fetchAutomaticService()
    },
    disableFeeIfServiceNotSelected () {
      if (!this.automaticTransaction.id) this.localValue = false
    },
    fetchAutomaticService () {
      this.$vs.loading()
      axios.get(`${process.env.VUE_APP_API_URL}/cash/transaction/automaticTransaction`, {
        params: {
          entity: this.entity
        }
      })
        .then(e => {
          if (e.data) {
            this.automaticTransaction = e.data
          }
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    }
  }
}
</script>
