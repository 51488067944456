<template>
  <div>
    <div class="vx-row">
      <select-service class="vx-col md:w-1/2" v-model="service" :use-id="true" :appendToBody="true"/>
      <select-account-plan class="vx-col md:w-1/2" v-model="accountPlan" :use-id="true" :appendToBody="true"/>
    </div>
    <select-type-payment-and-account v-model="typePaymentAndAccount" :appendToBody="true" />
    <vs-divider />
    <div class="flex flex-row-reverse">
      <vs-button @click="save">Salvar</vs-button>
    </div>
  </div>
</template>

<script>
import SelectTypePaymentAndAccount from '@/components/financial/typePayment/SelectTypePaymentAndAccount.vue'
import SelectAccountPlan from '@/components/financial/accountPlan/SelectAccountPlan.vue'
import SelectService from '@/components/register/service/SelectService.vue'
import axios from '@/axios'

export default {
  components: {
    SelectTypePaymentAndAccount,
    SelectAccountPlan,
    SelectService
  },
  props: {
    value: {}
  },
  data () {
    return {
      accountPlan: null,
      service: null,
      typePaymentAndAccount: {
        account: null,
        typePayment: null
      }
    }
  },
  watch: {
    value (automaticTransaction) {
      if (automaticTransaction.account_id) this.typePaymentAndAccount.account = {id: automaticTransaction.account_id}
      if (automaticTransaction.type_payment_id) this.typePaymentAndAccount.typePayment = {id: automaticTransaction.type_payment_id}
      if (automaticTransaction.service_id) this.service = automaticTransaction.service_id
      if (automaticTransaction.account_plan_id) this.accountPlan = automaticTransaction.account_plan_id
    }
  },
  methods: {
    save () {
      if (!this.validate()) return

      this.$vs.loading()
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/cash/transaction/automaticTransaction`,
          this.getAutomaticService()
        )
        .then((e) => {
          this.$emit('input', e.data)
          this.$emit('saved')
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    getAutomaticService () {
      return {
        ...this.value,
        account_id: this.typePaymentAndAccount.account.id,
        type_payment_id: this.typePaymentAndAccount.typePayment.id,
        service_id: this.service,
        account_plan_id: this.accountPlan
      }
    },
    validate () {
      const rules = [
        () => {
          if (this.service) return true
          this.mixinNotify('Selecione o serviço', 'danger')
        },
        () => {
          if (this.accountPlan) return true
          this.mixinNotify('Selecione o plano de conta', 'danger')
        },
        () => {
          if (!this.typePaymentAndAccount.typePayment) {
            this.mixinNotify('Selecione o tipo de pagamento', 'danger')
            return
          }
          if (!this.typePaymentAndAccount.account) {
            this.mixinNotify('Selecione a conta', 'danger')
            return
          }
          return true
        }
      ]
      for (const isValid of rules) {
        if (!isValid()) return
      }
      return true
    }
  }
}
</script>

<style>
</style>
